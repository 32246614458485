<template>
  <div id="app">
    <TopHeader site-title="Vue App"/>
    <ContentsList/>
  </div>
</template>

<script>
import TopHeader from './components/TopHeader.vue'
import ContentsList from './components/ContentsList.vue'

export default {
  name: 'App',
  components: {
    TopHeader,
    ContentsList
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
</style>
