<template>
  <div class="contents-list">
    <div class="search">
      <input v-model="searchText" type="search" placeholder="質問タイトル検索...">
    </div>
    <div class="content" v-for="content in search" :key="content.title">
      <span>{{ content.category }}</span>
      <span>回答数: {{ content.answerCount }}</span>
      <span>作成者: {{ content.author }}</span>
      <span>作成日: {{ content.createDate }}</span>
      <h2>{{ content.title }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentsList',
  data() {
    return {
      searchText: '',
      contents: [
        {
          title: 'どうやってもVlookup関数が理解できない...なにかいい方法ありませんか？',
          category: 'IT',
          answerCount: 2,
          author: '田中',
          createDate: "2021-01-01"
        },
        {
          title: 'ビジネスメールが書けません...うまく書けるテンプレートみたいなのありますか？',
          category: 'ビジネス',
          answerCount: 5,
          author: '鈴木',
          createDate: "2021-02-01"
        },
        {
          title: 'どうしてもお客様と話すとき緊張してしまいます。緊張しないで話せるようになるにはどうしたらいいですか？',
          category: 'コミュニケーション',
          answerCount: 3,
          author: '中村',
          createDate: "2021-03-01"
        },
        {
          title: '社内の育休制度について教えてほしいです。',
          category: '社内',
          answerCount: 1,
          author: '佐藤',
          createDate: "2021-04-01"
        },
        {
          title: '相手に伝わる話しかたってありますか？話すとき認識違いが多くて...',
          category: 'コミュニケーション',
          answerCount: 10,
          author: '斉藤',
          createDate: "2021-05-01"
        },
        {
          title: '社内用語集みたいなのってどこかにありますか？',
          category: '社内',
          answerCount: 1,
          author: '山田',
          createDate: "2021-06-01"
        },
        {
          title: '社内の休日カレンダーってどこかにありますか？',
          category: '社内',
          answerCount: 1,
          author: '吉川',
          createDate: "2021-07-01"
        },
        {
          title: '部下に気持ちよく働いてもらうには...',
          category: 'コミュニケーション',
          answerCount: 0,
          author: '山下',
          createDate: "2021-08-01"
        },
        {
          title: '無知ですいません...OutlookでTeams会議招集ってどうやればいいですか？',
          category: '社内',
          answerCount: 1,
          author: '渡辺',
          createDate: "2021-09-01"
        }
      ]
    }
  },
  computed: {
    search: function() {
      return this.contents.filter(content => {
        return content.title.includes(this.searchText)
    })}
  }
}
</script>

<style scoped>
.search input {
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 50px 20px 50px 20px;
  padding: 10px 20px;
  width: 500px;
  outline: none;
}

.contents-list {
  color: #666;
  margin: 100px auto;
  width: 60%;
}

.content {
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  margin: 20px 0 0 0;
  padding: 20px;
  text-align: left;
  width: 100%;
  height: 100%;
}

.content h2 {
  font-size: 20px;
  margin: 10px 0 20px 0;
}

.content span {
  font-size: 16px;
  font-weight: 600;
  margin: 0 20px 0 0;
}
</style>